import styled from "styled-components";
import React, {useCallback} from "react";
import EthImg from "../../assets/imgs/eth.png"
import MaticImg from "../../assets/imgs/matic.png"

export const LaunchButton:React.FC<{height: number}> = ({height}) => {
    return (
        <ButtonStyle height={height}>
            <div>
                <p>Launch App</p>
                <ImgWidget showBorder={false} img={EthImg} url={"https://app.nsure.network"}/>
            </div>
            <p>
                <ImgWidget img={MaticImg} url={"https://polygon.nsure.network/"}/>
            </p>
        </ButtonStyle>
    )
}


const ImgWidget: React.FC<{ img: string, url: string , showBorder?: boolean}> = ({img, url, showBorder= true}) => {

    const cbFn = useHref(url)

    return (
        <ImgStyle showBorder={showBorder} onClick={cbFn}>
            <img src={img} alt=""/>
        </ImgStyle>
    )
}

/**
 * hook
 * @constructor
 */
const useHref = (hrefAddress: string) => {
    return useCallback(() => {
        window.open(hrefAddress, "_blank");
    }, [])
}

const ButtonStyle = styled.div<{height: number}>`
  display: flex;
  height: ${({height}) => `${height}px`};

  > div {
    margin-right: 20px;
    line-height: 0;
    font-size: 16px;
    color: #270D4F;
    border-radius: 4px;
    border: 1px solid rgba(39, 13, 79, .2);
    background: linear-gradient(90deg, #CE45C5 0%, #5592E8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    display: flex;
    align-items: center;

    p{
      padding:0 16px;
    }
  }

`

const ImgStyle = styled.div<{showBorder:boolean}>`
  width: 70px;
  cursor: pointer;
  height: 100%;
  box-sizing: border-box;
  background: #EDEEFF;
  border-radius: 4px;
  border: ${({showBorder})=> showBorder ? '1px solid #D4CFDC' : ''};
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    display: block;
    width: 38px;
  }
`
