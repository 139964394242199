import styled from "styled-components";
import {ButtonWidget} from "../../../widget/pub/button";
import {Grid} from "@material-ui/core";
import React from "react";

export const BottomWidget: React.FC = () => {
    return (
        <BottomStyle>


            <h3>Bounty Program  for Beta release</h3>
            <ButtonWidget  href={"https://immunefi.com/bounty/nsurenetwork/"} text={'Report a Bug'}/>
        </BottomStyle>
    );
}


const BottomStyle = styled.div`
  text-align: center;
  margin: 100px 0 50px 0;


  h2 {
    font-size: 36px;
    font-weight: 800;
    color: #270D4F;
  }

  .button_wap {
    margin: 59px auto 40px auto;
    max-width: 700px;
    width: 100%;

    span {
      padding: 24px 0;
      line-height: 0;
      width: 100%;
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
      color: #270D4F;

      border-radius: 4px;
      border: 1px solid rgba(39, 13, 79, .2);
    }

    p {
      flex: 1;
    }
  }

  h3 {
    font-size: 18px;
    font-weight: 400;
    color: #270D4F;
    margin-bottom: 29px;
  }

  @media(max-width: 768px){
    margin: 40px 0;
    .button_wap{
      margin: 20px auto;

    }
  }
`
