import React, {useEffect} from "react";
import {Grid} from "@material-ui/core";
import {WapStyle} from "./w2";
import {
    Area,
    AreaChart,
    CartesianGrid,
    ResponsiveContainer,
    TickFormatterFunction,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {useWait} from "../../../utils/wait_fn";
import {HttpServer} from "../../../net/http_server";
import {W4Widget} from "./w4";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import {DateUtils} from "../../../utils/date";
import {getBalanceFormat, getBalanceNumber} from "../../../utils/formatBalance";

type cellModal = {

    annualizedPremiums: number
    date: string
    policyValue: number
}
export const W5Widget: React.FC = () => {
    const {
        request,
        data
    } = useWait<{ all: cellModal[] }>(HttpServer.policyValueAndAnnualizePremiums);


    useEffect(() => {
        request('');
    }, [])
    return (
        <>
            <W4Widget active={data?.all[data?.all.length -1]?.annualizedPremiums || 0} policy={data?.all[data?.all.length -1]?.policyValue || 0}/>
            <Grid container spacing={4}>
                <Grid item sm={6} xs={12}>
                    <W5Style>
                        <h2 className="title">Policy Value (in USD Million)</h2>
                        <AverAgeWidget name={"Value"} areaKey={'policyValue'}
                                       tickFormatter={(label: any) => {
                                           return (getBalanceNumber(label, 1) / 1e7).toFixed(2) + " M"
                                       }}
                                       listData={
                                           data?.all || []
                                       }/>
                    </W5Style>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <W5Style>
                        <h2 className="title">Annualized Premium (in USD)</h2>
                        <AverAgeWidget
                            tickFormatter={(label: any) => {
                                return getBalanceFormat(label,0);
                            }}
                            name={"Value"} areaKey={'annualizedPremiums'} listData={
                            data?.all || []
                        }/>

                    </W5Style>
                </Grid>
            </Grid>
        </>

    );
}


export const AverAgeWidget: React.FC<{ listData: any, areaKey: string, name: string, tickFormatter: TickFormatterFunction }> = ({
                                                                                                                       listData,
                                                                                                                       areaKey,
                                                                                                                       tickFormatter,
                                                                                                                       name
                                                                                                                   }) => {

    const c1 = "#2D97EE";
    const c2 = "#fff";


    return (

        <ResponsiveContainer
            width="100%" height="100%"
        >
            <AreaChart height={300} width={900} data={listData} margin={{top: 20, right: 0, bottom: 0, left: 0}}>

                <Tooltip separator={': '} labelFormatter={(label) => {

                    return '';
                }} label={'sss'}
                         formatter={(label: any) => getBalanceFormat(label, 0)}
                />
                <CartesianGrid strokeDasharray="1 1"/>
                <defs>
                    <linearGradient id="split" x1="0" y1="0" x2="0" y2="100%">
                        <stop style={{stopColor: c1,}} offset={'0%'}/>
                        <stop style={{stopColor: c2,}} offset={'70%'}/>
                    </linearGradient>

                </defs>
                <Area dataKey={areaKey}
                      fill="url(#split)"
                      stroke={c1}
                      name={name}

                />
                <XAxis dataKey="date" tickFormatter={(label) => {
                    return DateUtils.dataNameString(label);

                }}/>
                <YAxis tickFormatter={tickFormatter} padding={{top: 60, bottom: 0}}/>

            </AreaChart>

        </ResponsiveContainer>

    );
};


const W5Style = styled(WapStyle)`
  .inner {
    height: 400px;
  }
`
