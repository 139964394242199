import React, {useEffect} from "react";
import {Grid} from "@material-ui/core";
import styled from "styled-components";
import {WapStyle} from "./w2";
import {useWait} from "../../../utils/wait_fn";
import {HttpServer} from "../../../net/http_server";
import BigNumber from "bignumber.js";
import {getBalanceFormat} from "../../../utils/formatBalance";

export const W4Widget: React.FC<{policy:number,active:number}> = ({
                                                                      policy,
                                                                      active
                                                                  }) => {

    return (
        <Grid container spacing={4}>
            <Grid item sm={6} xs={12}>
                <InnerStyle>
                    <h3>Active Policy Value</h3>
                    <h4><span>$ </span>{getBalanceFormat(policy,0)}</h4>
                </InnerStyle>
            </Grid>
            <Grid item sm={6} xs={12}>
                <InnerStyle>
                    <h3>Active Annualized Premium</h3>
                    <h4><span>$ </span>{getBalanceFormat(active,0)}</h4>
                </InnerStyle>
            </Grid>
        </Grid>
    );
}

const InnerStyle = styled(WapStyle)`
  height: 153px;
  text-align: center;
  min-height: 153px;
  h3{
    font-size: 18px;
    font-weight: bold;
    color: #222222;
  }
  h4{
    span{
      font-size: 24px;
      font-weight: bold;
      color: #222222;
    }
    font-size: 36px;
    font-weight: bold;
    color: #222222;
  }
`
