import React, {useEffect, useState} from "react";
import {TitleStyle} from "../style";
import {PieConfig} from "@ant-design/charts/es/pie";
import {Line, Area} from "@ant-design/charts";
import {LineConfig} from "@ant-design/charts/lib/line";
import {AreaConfig} from "@ant-design/charts/es/area";

export const TokenEconomyWidget3: React.FC = () => {
    return (
        <>
            <div style={{height: '30px'}}/>
            <TitleStyle>
                <span/>
                <h2>Vesting Schedule</h2>
            </TitleStyle>
            <div style={{height: '20px'}}/>
            <DemoLine/>
            <div style={{height: '130px'}}/>
        </>
    );
}

const listInt: Array<{ name: string, list: Array<number> }> = [

    {
        name: "TGE",
        list: [4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000,],
    },

    {
        name: "Seed",
        list: [200000, 200000, 200000, 400000, 400000, 400000, 600000, 600000, 600000, 800000, 800000, 800000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000, 1000000,],
    },
    {
        name: "Marketing&Operation",
        list: [2000000, 2000000, 2000000, 2000000, 2000000, 2000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 4000000, 8000000, 8000000, 8000000, 8000000, 8000000, 8000000, 8000000, 8000000, 8000000, 10000000, 10000000, 10000000, 10000000, 10000000, 10000000,],
    },
    {
        name: "Private Sale",
        list: [2800000, 2800000, 2800000, 5600000, 5600000, 5600000, 8400000, 8400000, 8400000, 11200000, 11200000, 11200000, 14000000, 14000000, 14000000, 14000000, 14000000, 14000000, 14000000, 14000000, 14000000, 14000000, 14000000, 14000000, 14000000, 14000000, 14000000, 14000000, 14000000, 14000000,],
    },

    {
        name: "Team",
        list: [0, 0, 0, 0, 0, 0, 1500000, 1500000, 1500000, 1500000, 1500000, 1500000, 3000000, 3000000, 3000000, 3000000, 3000000, 3000000, 4500000, 4500000, 4500000, 4500000, 4500000, 4500000, 6000000, 6000000, 6000000, 6000000, 6000000, 6000000],
    },
    {
        name: "Capital Mining",
        list: [0, 345600, 691200, 1036800, 1382400, 1728000, 2073600, 2419200, 2764800, 3110400, 3456000, 3801600, 4147200, 4492800, 4838400, 5184000, 5529600, 5875200, 6220800, 6566400, 6912000, 7257600, 7603200, 7948800, 8294400, 8640000, 8985600, 9331200, 9676800, 10022400],
    },

];


const DemoLine: React.FC = () => {
    let startDate: Date = new Date('2020/09/01');



    const [list,setList] = useState([]);
    let dateList:Array<Date> = []
    useEffect(()=>{
        let _allList:any = [];
        for(let i = 0;i<30;i++){
            startDate.setMonth(startDate.getMonth() +1);
            dateList.push(new Date(startDate.valueOf()));
        }
        listInt.forEach((ev)=>{
            let _listTml  = ev.list.map((_number,i)=>{
                return {
                    date:dateList[i] ,
                    name:ev.name,
                    value:_number
                };
            });
            _allList = [..._allList,..._listTml]

        })
        _allList.reverse();
        setList(_allList);


    },[])

    /* let config:LineConfig = {
         data: data,
         xField: 'month',
         yField: 'value',
         legend: false,
         seriesField: 'key',
         smooth:true,
     };*/

    let config:AreaConfig = {
        data: list,
        xField: 'date',
        yField: 'value',
        seriesField: 'name',
        point:{
            color: ['#d62728', '#2ca02c', '#000000'],
        },
        xAxis: {
            type: 'time',
            mask: 'YYYY-MM',
            tickCount:5,
            label:{
                formatter:(value, idx,index)=>{
                    let _date = new Date(value);
                    return index == 0 ? "TGE" : [_date.getFullYear(), _date.getMonth() + 1].join("-");
                }
            },
        },
        yAxis: {
            label: {
                formatter: (value, idx) => {
                    return (parseInt(value) / 1000000) + "M";
                }
            },
        },
    };
    return <Area {...config} />;
    // return <Line {...config} />;
};
