import React from "react";
import {TitleStyle} from "../style";
import {Pie} from "@ant-design/charts";
import {PieConfig} from "@ant-design/charts/es/pie";

export const TokenEconomyWidget2: React.FC = () => {
    return (
        <>
            <div style={{height: '30px'}}/>
            <TitleStyle>
                <span/>
                <h2>Token Distribution</h2>
            </TitleStyle>
            <DemoPie/>
        </>
    );
}


const DemoPie: React.FC = () => {
    let data = [
        {
            type: 'Capital/lnsurance mining',
            value: 55000000,
            color: 'red',
        },
        {
            type: 'Seed round',
            value: 1000000,
        },
        {
            type: 'Private Sale',
            value: 14000000,
        },
        {
            type: 'Foundation Reserve',
            value: 10000000,
        },
        {
            type: 'Marketing & Operations',
            value: 10000000,
        },
        {
            type: 'Team',
            value: 6000000,
        },
        {value: 4000000, type: "TGE",},
    ];

    let configs: PieConfig = {
        appendPadding: 10,
        data: data,
        angleField: 'value',
        colorField: 'type',
        color: ['rgba(41, 46, 230, 1)', 'rgba(246, 51, 51, 1)', 'rgba(30, 208, 240, 1)', 'rgba(111, 50, 235, 1)', 'rgba(217, 147, 47, 1)', 'rgba(44, 234, 111, 1)'],
        radius: 0.8,
        innerRadius: 0.8,
        label: {
            type: 'spider',
            labelHeight: 28,
            content: '{name}\n{percentage}',
            style: {}
        },
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
                formatter: function formatter() {
                    return '';
                },
            },
        },
        // interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
    };
    return <Pie {...configs} />;
};
