
import logoImage from "../../assets/imgs/logo.png"
import styled from "styled-components";
import React from "react";

export const LogoWidget: React.FC<{color?:string,center?:string}> = ({center= 'center',color = '#270D4F'}) => {
    return (
        <StyledLogo  center={center} href="https://nsure.network/#/"  target="_blank">
            <img src={logoImage} height="50" alt={'https://nsure.network/#/'}/>
            <StyledText color={color}>
                Nsure.Network
            </StyledText>
        </StyledLogo>
    )
};



const StyledLogo = styled.a<{center:string}>`
  align-items: center;
  display: flex;
  justify-content: start;
  margin: 0;
  min-height: 44px;
  min-width: 44px;
  padding: 0;
  text-decoration: none;
  @media (max-width: 768px){
    justify-content: ${({center})=>center};

  }
`;

const StyledText = styled.span<{color:string}>`
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.03em;
  color: ${({color}) => color};
  margin-left: 10px;

`;
