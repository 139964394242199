import React from "react";
import styled from 'styled-components'
import {NavLink} from 'react-router-dom'
import {LaunchButton} from "../publi/launch_button";
import {Hidden} from "@material-ui/core";

export const NavWidget: React.FC = () => {


    return (<WapStyle>

        <StyledLink to="/home">Overview</StyledLink>
        {/*<StyledLink to="/un_name_page">Dashboard</StyledLink>*/}
        {/*<HerfStyle href={"https://staking.nsure.network"} target="_blank">Underwriter program</HerfStyle>*/}
        <StyledLink to="/capital">About</StyledLink>
        <StyledLink to="/token_economy">Nsure-nomics</StyledLink>
        <StyledLink to="/stats">Stats</StyledLink>
        <ShowHerf href={"https://community.nsure.network/"} target="_blank">Forum</ShowHerf>
        <Hidden mdDown>
            <ShowHerf href={"https://docs.nsure.network/nsure-network/docs"} target="_blank">Docs</ShowHerf>
            <LaunchButton height={40} />
        </Hidden>

        {/*<BorderStyle>*/}
        {/*    <HerfStyle href={"https://app.nsure.network/#/cover/my"} target="_blank">Launch App</HerfStyle>*/}
        {/*</BorderStyle>*/}

    </WapStyle>);
}

const WapStyle = styled.div`
  display: flex;
  justify-content: flex-end;
align-items: center;
  @media (max-width: 980px) {
    display: flex;
    margin-top: 10px;
    justify-content: center;
  }
`
const BorderStyle = styled.div`
  border: 1px solid rgba(39,13,79,.2);
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid rgba(39, 13, 79, .2);
  >a{
    margin: 0;
    background: linear-gradient(90deg, #CE45C5 0%, #5592E8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`
const ShowHerf = styled.a`
  margin-right: 20px;
  font-size: 16px;
  text-decoration: none;
  color: #270D4F;
  font-weight: bold;
`
const HerfStyle = styled.a`
  margin-right: 20px;
  font-size: 16px;
  text-decoration: none;
  color: #270D4F;
  font-weight: bold;

  @media (max-width: 768px) {
    //display: flex;
    margin-top: 10px;
    justify-content: center;
    display: none;
  }
`

const StyledLink = styled(NavLink)`
  margin-right: 20px;
  font-size: 16px;
  text-decoration: none;
  color: #270D4F;
  font-weight: bold;


  &:last-child {
    margin-right: 0;
  }

  &:hover {

  }

  &.active {


    background: linear-gradient(90deg, #CE45C5 0%, #5592E8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;

  }

  @media (max-width: 768px) {

    font-size: 14px;

  }
`;
