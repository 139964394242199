import React from "react";
import styled from "styled-components";
import {WidthStyle} from "../../style/width_style";
import {TokenEconomyWidget1} from "./widget/t1";
import {TokenEconomyWidget2} from "./widget/t2";
import {TokenEconomyWidget3} from "./widget/t3";

export const TokenEconmyPage: React.FC = () => {
    return (
        <>
            <WapStyle>
                <TokenEconomyWidget1 />
                <TokenEconomyWidget2 />
                <TokenEconomyWidget3 />
            </WapStyle>

        </>

    );
}

const WapStyle = styled.div`
${WidthStyle.MaxWidth};
  padding-top: 50px;
  box-sizing: border-box;
    
`;
