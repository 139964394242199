import React from "react";
import {Grid} from "@material-ui/core";
import styled from "styled-components";
import i1 from '../../assets/imgs/bottom/1.png'
import i2 from '../../assets/imgs/bottom/2.png'
import i3 from '../../assets/imgs/bottom/3.png'
import i4 from '../../assets/imgs/bottom/4.png'
import i5 from '../../assets/imgs/bottom/5.jpg'
import i6 from '../../assets/imgs/bottom/defipulse.png'
import i7 from '../../assets/imgs/bottom/github_bo.png'

let ProtocolList = [
    {
        text: 'Markets',
        url: "https://app.nsure.network/#/cover/my"
    },
    {
        text: 'Staking',
        url: "https://staking.nsure.network/#/"

    },

    /*  {
          text: 'NSURE',
      },*/
];

let CompanyList = [
    {
        text: 'Nsure Labs',
        router: "/capital/labs"
    },
    {
        text: 'Team',
        router: "/capital/team"
    },
    {
      
        text: 'Careers',
        router: "/capital/join"
    },
];

let RDList = [
    {
        text: 'WhitePaper',
        url: "https://nsure.network/Nsure_WP_0.7.pdf"
    },
    {
        text: 'Docs',
        url: "https://docs.nsure.network/nsure-network/"
    },
    {
        text: 'Roadmap',
        router: "/capital/roadmap"
    },
];

export const BottomMain: React.FC = () => {
    return (
        <div>
            <Grid container>
                <GridWidget title={'Protocol'} listData={ProtocolList}/>
                <GridWidget title={'About'} listData={CompanyList}/>
                <GridWidget title={'R&D'} listData={RDList}/>
                <GridWidget title={'Contact'}
                            listData={[{text: 'contact@nsure.network', url: "mailto:contact@nsure.network",}]}/>
            </Grid>
            <BottomIconStyle>
                <a href="https://www.dextools.io/app/uniswap/pair-explorer/0xed196f5f01e8c99ef4c6d77b83ccaf629032a8f2"
                   target="view_window"><img src={i5} alt=""/></a>
                <a href="https://twitter.com/Nsure_network" target="view_window"><img src={i1} alt=""/></a>
                <a href="https://discord.com/invite/nSvAapa" target="view_window"><img src={i2} alt=""/></a>
                <a href="https://t.me/nsurenet" target="view_window"><img src={i3} alt=""/></a>
                <a href="https://nsure-network.medium.com/" target="view_window"><img src={i4} alt=""/></a>
                <a href="https://defipulse.com/" target="view_window"><img src={i6} alt=""/></a>
                <a href="https://github.com/nsure-tech" target="view_window"><img src={i7} alt=""/></a>
            </BottomIconStyle>
            <BottomIconHide>
                <div>
                    <a href="https://www.dextools.io/app/uniswap/pair-explorer/0xed196f5f01e8c99ef4c6d77b83ccaf629032a8f2"
                       target="view_window"><img src={i5} alt=""/></a>
                    <a href="https://twitter.com/Nsure_network" target="view_window"><img src={i1} alt=""/></a>
                    <a href="https://discord.com/invite/nSvAapa" target="view_window"><img src={i2} alt=""/></a>
                </div>
                <div>
                    <a href="https://t.me/nsurenet" target="view_window"><img src={i3} alt=""/></a>
                    <a href="https://nsure-network.medium.com/" target="view_window"><img src={i4} alt=""/></a>
                    <a href="https://defipulse.com/" target="view_window"><img src={i6} alt=""/></a>
                    <a href="https://github.com/nsure-tech" target="view_window"><img src={i7} alt=""/></a>
                </div>

            </BottomIconHide>
        </div>
    );
}


const GridWidget: React.FC<{ title: string, listData: { text: string, url?: string, router?: string }[] }> = ({
                                                                                                                  title,
                                                                                                                  listData
                                                                                                              }) => {
    return (
        <GridStyle item md={3} sm={6} xs={6}>
            <p className={'title'}>{title}</p>
            {
                listData.map((ev) => {
                        if (ev.url)
                            return <a key={ev.text} href={ev.url}>{ev.text}</a>;
                        else
                            return <a key={ev.text} href={'/#' + ev.router}>{ev.text}</a>;
                    }
                )
            }
        </GridStyle>
    );
}

const BottomIconHide = styled.div`
  display: none;
  @media ( max-width: 700px) {
    justify-content: space-between;
    flex-flow: wrap;
    display: block;
    >div{
      display: flex;
      justify-content: space-between;
    }
    a {
      margin-bottom: 20px;

      img {
        margin-right: 0;
        opacity: .6;
        display: block;
        max-width: 30px;
        max-height: 30px;
      }
      
    }
  }
`

const BottomIconStyle = styled.div`
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: flex-end;

  img {
    opacity: .6;
    display: block;
    margin-right: 50px;
    max-width: 30px;
    max-height: 30px;
  }

  a:last-child {
    img {
      margin-right: 0;
    }
  }

  @media ( max-width: 700px) {
    justify-content: space-between;
    flex-flow: wrap;
    display: none;
    a {
      width: 33%;
      margin-bottom: 20px;

      img {
        margin-right: 0;
      }

      &:last-child {
        img {
          margin-right: 0;
        }
      }

      &:nth-child(3n+0) {
        img {
          float: right;
        }

        text-align: end;
      }

      &:nth-child(2n+3) {
        img {
          margin: 0 auto;
        }

        text-align: end;
      }
    }
  }
`

const GridStyle = styled(Grid)`


  .title {
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 30px;

  }

  a {
    font-size: 14px;

    font-weight: 400;
    color: RGBA(255, 255, 255, .6);
    margin-bottom: 25px;
    text-decoration: none;
    display: block;
  }

  p:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 960px) {
    .title {
      margin-top: 20px;
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 15px;
    }
  }
`
