import React from "react";
import styled from "styled-components";
import {Grid} from "@material-ui/core";
import right_Icon from '../../../assets/imgs/right_Icon.png'
import {ButtonWidget} from "../../../widget/pub/button";

export const CapitalWidget3: React.FC = () => {
    return (
        <div>
            <TitleStyle>
                <span/>
                <h2>Join us</h2>
                <h3>Help building the insurance backbone for Finance 2.0</h3>
            </TitleStyle>

            <BorderStyle>
                <a href="mailto:contact@nsure.network">
                    Interested in joining us? Send us an email with your resume and cover letter
                    to: <span>contact@nsure.network</span>
                </a>
                {/*<div>*/}
                {/*    <a href="mailto:contact@nsure.network">Contact us</a>*/}

                {/*</div>*/}
                {/*<Grid container justify={"space-around"}>*/}
                {/*    <GridCellWidget/>*/}
                {/*    <GridCellWidget/>*/}
                {/*    <GridCellWidget/>*/}
                {/*    <GridCellWidget/>*/}
                {/*    <GridCellWidget/>*/}
                {/*    <GridCellWidget/>*/}
                {/*</Grid>*/}
            </BorderStyle>
        </div>
    );
}

// const GridCellWidget: React.FC = () => {
//     return (
//         <GridStyle item md={5} sm={12} xs={12}>
//             <div>
//                 <h2>Engineering Manager </h2>
//                 <h4>San Francisco, CA</h4>
//                 <img src={right_Icon} alt=""/>
//             </div>
//         </GridStyle>
//     );
// }


const BorderStyle = styled.div`
  border: 1px solid rgba(165, 74, 249, .3);
  padding: 20px 30px;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 30px 0;

  a {
    font-size: 20px;
    font-weight: 600;
    color: #270D4F;
    text-decoration: none;

    span {
      text-decoration: underline;
    }
  }

  div {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    a {
      text-decoration: none;
      border: 1px solid rgba(165, 74, 249, .3);
      border-radius: 4px;
      padding: 10px 20px;
      display: inline-block;
      float: right;
      color: #270D4F;
    }
  }
`

const TitleStyle = styled.div`
  margin-top: 50px;

  span {
    width: 30px;
    height: 2px;
    display: block;
    background-color: rgba(165, 74, 249, 1);
  }

  h2 {
    font-size: 36px;
    font-weight: 800;
    color: #270D4F;
    line-height: 1;
    margin: 15px 0 20px 0;
  }

  h3 {
    font-size: 16px;
    font-weight: 800;
    color: #9A82BE;
  }
`

