export interface DateInterface {
    dataString(data: Date | string | number | undefined): string;

    dataNameString(data: Date | string | number | undefined): string;

    dataMinuteString(data: Date | string | number | undefined): string;

}

const MOUNT = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'

];
export let DateUtils: DateInterface = {
    dataString(data: Date | string | number | undefined): string {
        data = getDate(data);
        return `${MOUNT[data.getMonth()]} ${data.getDate()}`;
    },
    dataNameString(data: Date | string | number | undefined): string {
        data = getDate(data);
        return `${MOUNT[data.getMonth()]} ${data.getDate()}`;
    },
    dataMinuteString(data: Date | string | number | undefined): string {
        data = getDate(data);
        let month = (data.getMonth() + 1).toString();
        return `${data.getFullYear()}/${dateLength(month)}/${dateLength(data.getDate().toString())} ${dateLength(data.getHours().toString())}:${dateLength(data.getMinutes().toString())}`;
    }
}

function dateLength(
    s1: string
) {
    return s1.length > 1 ? s1 : '0' + s1;
}

function getDate(data: Date | string | number | undefined): Date {
    if (data === undefined) return new Date();
    if (typeof data === "string") data = new Date(data);
    if (typeof data === "number") data = new Date(data);
    return data;
}
