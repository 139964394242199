import styled from "styled-components";

export const TitleStyle = styled.div<{ color?: string }>`
  span {
    display: block;
    width: 30px;
    height: 2px;
    background: #A54AF9;
  }

  h2 {
    font-size: 36px;
    font-weight: 800;
    color: ${({color}) => color || '#270D4F'};
  }
`
