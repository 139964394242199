import React from "react";
import styled from "styled-components";
import {WidthStyle} from "../../style/width_style";
import {Grid} from "@material-ui/core";
import {LogoWidget} from "../header/logo";
import {BottomMain} from "./bottom_main";

export const BottomIndex:React.FC = ()=>{
    return (
        <BgStyle>
            <WapStyle>
                <Grid container justify={"space-between"}>
                    <Grid item md={2} sm={12} xs={12}>
                        <LogoWidget center={'start'} color={'#fff'} />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <BottomMain />
                    </Grid>
                </Grid>
            </WapStyle>
        </BgStyle>
    );
}


const WapStyle = styled.div`
    ${WidthStyle.MaxWidth};
`

const BgStyle = styled.div`
  background-color: rgba(4, 1, 17, 1);
  padding: 30px;
  box-sizing: border-box;
`
