import React from "react";
import {Grid} from "@material-ui/core";
import {ButtonWidget} from "../../../widget/pub/button";
import H2Image from "../../../assets/imgs/h3.png";
import styled from "styled-components";
import {WidthStyle} from "../../../style/width_style";
import {ImageStyle, TextCellStyle} from "../style";

export const HomeWidget2: React.FC = () => {
    return (
        <WapStyle>
            <Grid container alignItems={"center"} justify={"space-between"}>
                <Grid item md={4} sm={12}>
                    <ImageStyle src={H2Image} alt=""/>
                </Grid>
                <TextCellStyle item md={7} sm={12}>
                    <span />
                    <h2>Capital Mining</h2>
                    <h4>
                        Provide capital to back insurance risks in the capital pool, or purchase insurance coverage to obtain NSURE tokens. NSURE are minted and automatically awarded on every block.
                    </h4>
                    <ButtonWidget href={"https://app.nsure.network/#/start_mining/stake_to_mine/start"} text={'Start Mining'}/>
                </TextCellStyle>

            </Grid>
        </WapStyle>
    );
}





const WapStyle = styled.div`
${WidthStyle.MaxWidth};

`
