import React, {useEffect} from "react";
import {Button, Dialog, Grid, Theme, withStyles,Typography,TooltipProps,Tooltip} from "@material-ui/core";
import styled from "styled-components";
import {useWait} from "../../../utils/wait_fn";
import {HttpServer} from "../../../net/http_server";
import {getBalanceFormat} from "../../../utils/formatBalance";

export const TopWidget: React.FC = () => {
    const {
        request,
        data
    } = useWait<{ capital: number, policy: number, underwrite: number ,averagePremiumReturn:number}>(HttpServer.estimatedThreeApr);




    useEffect(() => {
        request('');
    }, [])
    return (
        <>
            <Grid container spacing={3}>
                <LastWidget last={data?.capital || 0}
                            title={'Estimated Capital Mining APR'}
                            tis={"Based on nETH staked"}
                />
                <LastWidget last={data?.policy || 0}
                            title={'Estimated Policy Mining APR'}
                            tis={"Based on total policy cost"}

                />
                <LastWidget last={data?.averagePremiumReturn || 0}
                            title={'Underwriting Average APR'}
                            tis={'Annualized Premium / 2 / Staking Power Used'}
                />
                <LastWidget last={data?.underwrite || 0}
                            title={'Underwriting Extra Reward'}
                            tis={'Based on Staking Power Used'}
                />

            </Grid>

        </>
    );
}
const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);


const LastWidget: React.FC<{ title: string, last: number,tis:string }> = ({title, last,tis}) => {
    return (
        <WapStyle item sm={6} md={3} xs={12}>
            <div className={'inner'}>
                <HtmlTooltip placement="top" title={
                    <React.Fragment>
                        <Typography color="inherit">{tis}</Typography>
                    </React.Fragment>
                }>
                    <h4>{title}</h4>

                </HtmlTooltip>
                <h3>{getBalanceFormat(last * 1e18,2)}%</h3>

            </div>
        </WapStyle>
    );
}

const WapStyle = styled(Grid)`
  padding: 20px;
  box-sizing: border-box;
  

  .inner {
    padding: 30px;
    box-sizing: border-box;

    box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.1);

    p,h4 {
      color: #666;
      font-size: 14px;
    }

    h4{
      cursor: pointer;
    }

    h3 {
      color: #222;
      margin-top: 15px;
      font-size: 30px;
      line-height: 49px;
      font-weight: bold;
    }
  }
`
