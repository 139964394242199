import React, {useEffect} from "react";
import {Area, AreaChart, Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {WapStyle} from "./w2";
import {useWait} from "../../../utils/wait_fn";
import {HttpServer} from "../../../net/http_server";
import {W6Style} from "./w6";
import {getBalanceFormat, getBalanceNumber} from "../../../utils/formatBalance";


export const W7Widget: React.FC = () => {

    const {
        request,
        data
    } = useWait<any>(HttpServer.activePremiumReturnbyProjects);


    useEffect(() => {
        request('');
    }, [])

    return (
        <W6Style>
            <h2 className="title">Annualized Premium (in USD)</h2>
            <AverAgeWidget listData={data || []}/>
        </W6Style>
    );
}

export const AverAgeWidget: React.FC<{ listData: any }> = ({listData}) => {

    const c1 = "#2D97EE";
    const c2 = "#fff";


    return (

        <ResponsiveContainer
            width="100%" height="100%"
        >
            <BarChart height={400} width={900} data={listData} margin={{top: 10, right: 0, bottom: 90, left: 0}}>

                <Tooltip
                    labelFormatter={(label) => {
                        return label;
                    }}
                    formatter={(label: any) =>{
                        return getBalanceFormat(label, 0) + ""
                    }}

                    separator={' '}
                    labelStyle={{
                        "color": "#8884d8"
                    }}
                />
                <CartesianGrid strokeDasharray="1 1"/>
                <defs>
                    <linearGradient id="split" x1="0" y1="0" x2="0" y2="100%">
                        <stop style={{stopColor: c1,}} offset={'0%'}/>
                        <stop style={{stopColor: c2,}} offset={'70%'}/>
                    </linearGradient>
                </defs>
                {/*<XAxis dataKey="id"  interval={0} dy={20} angle={90} dx={0} tickFormatter={(label) => {*/}
                {/*    return label*/}
                {/*}}/>*/}
                <XAxis dataKey="id"
                       interval={0}
                       dy={40}
                       angle={70}
                       dx={10}
                       tickFormatter={(label) => {
                           // formatter={(label: any) => getBalanceFormat(label, 0)}
                           return label;
                           // return  getBalanceFormat(label, 1);
                       }}
                />
                <YAxis padding={{top: 60, bottom: 0}}
                       tickFormatter={(label: any) => {
                           return getBalanceFormat(label,0);
                       }}
                       // tickFormatter={(label) => {
                       //     return getBalanceFormat(label * 1e18, 2) + " %"
                       //     // return  getBalanceFormat(label, 1);
                       // }}
                />
                <Bar dataKey="apr" name=" " fill="#8884d8"/>
            </BarChart>

        </ResponsiveContainer>

    );
};
