import React, {useCallback, useEffect, useMemo, useRef} from "react";
import styled from "styled-components";
import {WidthStyle} from "../../style/width_style";
import {HomeWidget1} from "./widget/h1";
import ZsImg from '../../assets/imgs/zs@2x.png';
import {HomeWidget2} from "./widget/h2";
import {HomeWidget4} from "./widget/h4";
import {HomeWidget3} from "./widget/h3";
import {BottomWidget} from "./widget/bottom";
import {InvestmentWidget} from "./widget/investment";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

let timeOut:any;
export const HomePage: React.FC = () => {

    let last_known_scroll_position = 0;

    const doSomething = useCallback((scroll_pos:any)=>{
            if (window.outerHeight/2 > scroll_pos) {
                scroller.scrollTo('test1', {
                    duration: 500,
                    delay: 100,
                    smooth: 'easeInOutQuint',
                })
            }
    },[scroller])




    useMemo(() => {
        Events.scrollEvent.register('begin', function(to, element) {
            if(timeOut){
                clearTimeout(timeOut)
            }
        });

        Events.scrollEvent.register('end', function(to, element) {
            if(timeOut){
                clearTimeout(timeOut)
            }
        });

        scrollSpy.update();
        window.addEventListener('scroll', (e) => {
            if (last_known_scroll_position > window.scrollY) {
                last_known_scroll_position = window.scrollY;
                return;
            }
            last_known_scroll_position = window.scrollY;

            if(timeOut){
                clearTimeout(timeOut)
            }

            timeOut = setTimeout(()=>{
                doSomething(last_known_scroll_position);
            },200)


        });
    }, [])
    return (
        <BgStyle>

            <HomeWidget1/>
            <Element name="test1" className="element" />

            <h3>
                Nsure is an open insurance platform for Open Finance. The project is inspired by
                Lloyd’s London, a market place to trade insurance risks, where premiums are
                determined by a dynamic pricing model.
            </h3>
            <HomeWidget2/>
            <HomeWidget3/>
            <HomeWidget4/>
            <BottomWidget/>
            <InvestmentWidget/>
        </BgStyle>

    );
}


const BgStyle = styled.div`
  background-image: url("${ZsImg}");
  background-size: 100%;
  background-repeat: no-repeat;

  > h3 {
    ${WidthStyle.MaxWidth};
    max-width: 563px;
    font-size: 16px;
    color: #270D4F;
    margin: 0 auto;
    padding: 84px 0 95px 0;
    box-sizing: border-box;
    text-align: center;
    font-weight: bold;
    //text-shadow: 0 2px 2px rgba(3, 3, 3, 0.36);
  }
`

