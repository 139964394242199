import React from "react";
import styled from "styled-components";

export const ButtonWidget: React.FC<{ text: string, href?: string }> = ({text, href}) => {
    return (
        <ButtonStyle onClick={() => {
            if (href) {
                window.open(href,"_blank");
            }
        }}>
            {text}
        </ButtonStyle>
    );
}

const ButtonStyle = styled.div`
  padding: 24px 40px;
  line-height: 0;
  display: inline-block;
  font-size: 16px;
  color: #270D4F;
  cursor: pointer;

  border-radius: 4px;
  border: 1px solid rgba(39, 13, 79, .2);
  background: linear-gradient(90deg, #CE45C5 0%, #5592E8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
`
