import React from "react";
import styled from "styled-components";
import {WidthStyle} from "../../../style/width_style";
import {Grid} from "@material-ui/core";
import based from '../../../assets/imgs/investment/based.png'
import au21 from '../../../assets/imgs/investment/au21.png'
import mechanism from '../../../assets/imgs/investment/mechanism.png'
import caballeros from '../../../assets/imgs/investment/caballeros.png'
import liaode from '../../../assets/imgs/investment/liaode.png'
import cenblock from '../../../assets/imgs/investment/cenblock.png'
import signal from '../../../assets/imgs/investment/signal.png'
import qunqun from '../../../assets/imgs/investment/qunqun.png'
import boring from '../../../assets/imgs/investment/boring.png'
import chain from '../../../assets/imgs/investment/chain.png'
import i88 from '../../../assets/imgs/investment/88.png'
import bitmax from '../../../assets/imgs/investment/bitmax.png'
import huobi from '../../../assets/imgs/investment/huobi.png'
import ccommas from '../../../assets/imgs/investment/3ccommas.png'
import web3js from '../../../assets/imgs/investment/web3js.png'
import polygonscan from '../../../assets/imgs/investment/polygonscan.png'


const Backed:{ imgUrl: string, href?: string }[] = [
    {
        imgUrl: based,
        href: 'https://based.ventures'
    },
    {
        imgUrl: mechanism,
        href: 'https://mechanism.capital'
    },
    {
        imgUrl: caballeros,
        href: 'http://caballeroscapital.com'
    },
    {
        imgUrl: ccommas,
        href: 'https://3commas.io'
    },
    {
        imgUrl: liaode,
        href: 'https://ldcap.com/'
    },
    {
        imgUrl: au21,
        href: 'https://au21.capital'
    },
    {
        imgUrl: cenblock,
        href: 'https://genblock.capital/'
    },
    {
        imgUrl: signal,
        href: 'https://Signal.vc'
    },
];
const Partners:{ imgUrl: string, href?: string }[] = [
    {
        imgUrl: qunqun,
        href: 'https://www.oasisprotocol.org/'
    },
    {
        imgUrl: boring,
        href: 'https://www.boringdao.com/'
    },
    {
        imgUrl: chain,
        href: 'https://chain.link/'
    },
    {
        imgUrl: i88,
        href: 'https://88mph.app/'
    },
    {
        imgUrl: bitmax,
        href: 'https://ascendex.com/en/basic/cashtrade-spottrading/usdt/nsure'
    },
    {
        imgUrl: huobi,
        href: 'https://www.huobi.com/en-us/exchange/nsure_usdt/'
    },
    {
        imgUrl: web3js,
        href: 'https://polygonscan.com/token/0x2d61f243d1a4c16640c3961100f50222679ec89e'
    },
    {
        imgUrl: polygonscan,
        href: 'https://github.com/nsure-tech/dot-contract'
    },
];
// const Exchanges:{ imgUrl: string, href?: string }[] = [
//     {
//         imgUrl: bitmax,
//         href: 'https://bitmax.io/en/basic/cashtrade-spottrading/usdt/nsure'
//     },
//     {
//         imgUrl: huobi,
//         href: 'https://www.huobi.com/en-us/exchange/nsure_usdt/'
//     },
//     // {
//     //     imgUrl: uniswap,
//     //     href: 'https://uniswap.org/'
//     // },
// ];
export const InvestmentWidget: React.FC = () => {
    return (
        <BgStyle>
            <WapStyle>
                <h2>Backed by</h2>
                <Grid container spacing={4} justify={'space-around'}>
                    {
                        Backed.map((ev) => {
                            return <ImgGrid key={ev.imgUrl} item md={3} sm={6} xs={12}>
                                <a href={ev.href}  target="view_window">                                <img src={ev.imgUrl} alt=""/>
                                </a>
                            </ImgGrid>;
                        })
                    }
                </Grid>
                <h2>Official Partners</h2>
                <Grid container spacing={4} justify={'space-around'}>
                    {
                        Partners.map((ev) => {
                            return <ImgGrid key={ev.imgUrl} item md={4} sm={6} xs={12}>
                                <a href={ev.href}  target="view_window">                                <img src={ev.imgUrl} alt=""/>
                                </a>
                            </ImgGrid>;
                        })
                    }
                </Grid>
                {/*<Grid container spacing={4} justify={'space-around'}>*/}
                {/*    {*/}
                {/*        Exchanges.map((ev) => {*/}
                {/*            return <ImgGrid key={ev.imgUrl} item md={3} sm={6} xs={12}>*/}
                {/*                <a href={ev.href}  target="view_window">                                <img src={ev.imgUrl} alt=""/>*/}
                {/*                </a>*/}
                {/*            </ImgGrid>;*/}
                {/*        })*/}
                {/*    }*/}
                {/*</Grid>*/}
            </WapStyle>
        </BgStyle>
    );
}
const ImgGrid = styled(Grid)`
  //display: flex;
  //justify-items: center;

  img {
    display: block;
    width: 100%;
    opacity: .7;
    transition: opacity .3s;
    &:hover{
      opacity: 1;
    }
  }
`
const WapStyle = styled.div`
  ${WidthStyle.MaxWidth};

  h2 {
    font-size: 36px;
    font-weight: 800;
    color: white;
    text-align: center;
    margin: 20px 0;
  }

`
const BgStyle = styled.div`
  background-color: #340C7B;
  padding: 30px;
  box-sizing: border-box;
`
