import React from "react";
import {Grid} from "@material-ui/core";
import c1 from '../../../assets/imgs/c1.png'
import styled from "styled-components";

export const CapitalWidget1: React.FC = () => {
    return (
        <WapStyle container alignItems={"center"}>
            <LeftStyle item md={6}>
                <h2>We're Nsure Labs.</h2>
                <h3>
                    The legacy financial system is slow, inefficient, and constrained by
                    intermediaries. We’re on a mission to change that.
                </h3>
                <h3>

                    Nsure Labs is an open-source software development organisation building
                    tools, products, and services for the decentralized finance (DeFi)
                    ecosystem.
                </h3>
            </LeftStyle>
            <Grid item md={6}>
                <ImgStyle src={c1} alt=""/>
            </Grid>
        </WapStyle>
    );
}
const WapStyle = styled(Grid)`
  flex-wrap: wrap-reverse !important;
`

const ImgStyle = styled.img`
  display: block;
  width: 100%;
`

const LeftStyle = styled(Grid)`
  h2 {
    font-size: 48px;
    font-weight: 800;
    color: #270D4F;
    margin-bottom: 30px;
    line-height: 1;
  }

  h3 {
    line-height: 1.2;
    font-size: 16px;
    font-weight: 400;
    color: #270D4F;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

`
