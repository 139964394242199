import React from "react";
import styled from "styled-components";
import {TopWidget} from "./widget/top_widget";
import {CapitalMain} from "./widget/w2";
import {W3Main} from "./widget/w3";
import {W4Widget} from "./widget/w4";
import {W5Widget} from "./widget/w5";
import {W6Widget} from "./widget/w6";
import {W7Widget} from "./widget/w7";
import {WidthStyle} from "../../widget/style/width_style";
import {W8Widget} from "./widget/w8";
import bgImg from "../../assets/imgs/pub_bg.png"
import {SoldPolicies} from "./widget/w9";

export const UnNamePage: React.FC = () => {
    return (
        <WapOneStyle>
            <BgStyle>
                <TopWidget/>

                <CapitalMain/>
                <W3Main/>
                <W5Widget/>
                <W6Widget/>
                <W8Widget/>
                <W7Widget/>
                <SoldPolicies />
            </BgStyle>
            <div className="bg"></div>
        </WapOneStyle>
    );
}

const WapOneStyle = styled.div`
  position: relative;

  .bg {
    background-image: url(${bgImg});
    position: fixed;
    top: 0;
    z-index: -1;
    height: 100vh;
    width: 100vw;
    background-size: 100%;
  }
`


const BgStyle = styled.div`
  background-size: 100%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  ${WidthStyle.MaxWidth};

`
