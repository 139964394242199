import React from "react";
import {TitleStyle} from "../style";
import {Grid} from "@material-ui/core";
import styled from "styled-components";
import t1 from '../../../assets/imgs/t1@2x.png'
import t2 from '../../../assets/imgs/t2@2x.png'
import t3 from '../../../assets/imgs/t3@2x.png'
import t4 from '../../../assets/imgs/t4@2x.png'
import t5 from '../../../assets/imgs/t5@2x.png'

export const TokenEconomyWidget1: React.FC = () => {

    return (
        <>
            <TitleStyle>
                <span/>
                <h2>Nsure Token Economy</h2>
            </TitleStyle>
            <TitleSubStyle>Key Metrics</TitleSubStyle>
            <Grid container spacing={3}>
                <KeyMetricsWidget title={'100M'} content={'100m Total supply'}/>
                <KeyMetricsWidget title={'55M'}
                                  content={'55m NSURE tokens will be redeemed by user participation in the network'}/>
                <KeyMetricsWidget title={'5M'}
                                  content={'5m tokens are allocated for participants of the NsureDAO to vote on'}/>
                <KeyMetricsWidget title={'5M'}
                                  content={'5m tokens dedicated to nurture and further build the Nsure Ecosystem'}/>
            </Grid>
            <TitleSubStyle>Utilities</TitleSubStyle>
            <Grid container spacing={3} justify={"space-around"}>
                <UtilitiesWidget image={t1}
                                 content={`Stake Nsure on insurance products to underwrite them, earn returns from premiums paid for policy purchases`}/>
                <UtilitiesWidget image={t2}
                                 content={`Use your Nsure for participating in claim resolutions, earning service fees for successful participation`}/>
                <UtilitiesWidget image={t3}
                                 content={`Nsure tokens present a governance framework to grant key decision-making to token holders.`}/>
                <UtilitiesWidget image={t4}
                                 content={`Governance defines a set of Policies by which the Nsure Protocol and its Risk Markets abide by.`}/>
                <UtilitiesWidget image={t5}
                                 content={`Participation in the network is set by ecosystem incentives to reward protocol growth`}/>
            </Grid>
        </>
    );
}

const UtilitiesWidget: React.FC<{ image: string, content: string }> = ({image, content}) => {
    return (
        <UtilitiesStyle item md={2} sm={5} xs={5}>
            <div>
                <img src={image} alt=""/>
                <h3>{content}</h3>
            </div>
        </UtilitiesStyle>
    );
}

const KeyMetricsWidget: React.FC<{ title: string, content: string }> = ({title, content}) => {
    return (
        <KeyMetricsStyle item md={3} sm={6} xs={6}>
            <div>
                <h2>{title}</h2>
                <h3>{content}</h3>

            </div>
        </KeyMetricsStyle>
    );
}

const UtilitiesStyle = styled(Grid)`
        
  margin-bottom: 20px !important;
  
  @media (min-width: 960px) {
    &.MuiGrid-grid-md-2 {
      flex-grow: 0;
      max-width: 20%;
      flex-basis: 20%;
    }
  }

  div {
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    padding: 25px 10px;
    box-sizing: border-box;

    img {
      display: block;
      margin: 0 auto;
      width: 40px;
    }

    h3 {
      font-size: 16px;
      margin-top: 10px;
      font-weight: 400;
      color: #270D4F;
      text-align: center;

    }
  }
`
const TitleSubStyle = styled.h3`
  text-align: center;
  margin: 20px 0;
  font-size: 24px;
  font-weight: 800;
  color: #270D4F;
`
const KeyMetricsStyle = styled(Grid)`
  div {
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    padding: 25px 30px;
    box-sizing: border-box;

    h2 {
      text-align: center;
      font-size: 28px;
      line-height: 1;
      font-weight: 800;
      color: #270D4F;
      background: linear-gradient(90deg, #CE45C5 0%, #5592E8 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    h3 {
      font-size: 16px;
      margin-top: 10px;
      font-weight: 400;
      color: #270D4F;
      text-align: center;
    }
  }
`
