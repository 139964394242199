import React from "react";
import {LogoWidget} from "./logo";
import styled from "styled-components";
import {NavWidget} from "./nav_widget";
import {Grid} from "@material-ui/core";

export const HeaderWidget: React.FC = () => {
    return (
        <HeaderStyle>
            <Grid container alignItems={"center"} justify={"space-between"}>
                <Grid item md={3} sm={12} xs={12}>
                    <LogoWidget/>
                </Grid>
                <Grid md={9} item sm={12} xs={12}>
                    <NavWidget/>
                </Grid>
            </Grid>

        </HeaderStyle>
    );
}

const HeaderStyle = styled.div`
  padding: 20px 0;
  max-width: 1100px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 10px 0;
  }
`
