import React, {useEffect} from "react";
import styled from "styled-components";
import {WidthStyle} from "../../style/width_style";
import {CapitalWidget1} from "./widget/c1";
import {CapitalWidget3} from "./widget/c3";
import {TokenEconomyWidget4} from "../token_economy/widget/t4";
import {Element,scroller} from "react-scroll";
import {useParams} from "react-router-dom";
export const CapitalPage: React.FC = () => {
    let slug: any = useParams();
    useEffect(()=>{
        scroller.scrollTo(slug.id, {
            duration: 500,
            delay: 100,
            smooth: 'easeInOutQuint',
        })
    },[slug])
    return (
        <>
            <WapStyle>
                <Element name="labs" className="element" />
                <CapitalWidget1/>
                {/*<Element name="team" className="element" />*/}
                {/*<CapitalWidget2/>*/}
                <Element name="join" className="element" />

                <CapitalWidget3/>

            </WapStyle>
            <Element name="roadmap" className="element" />

            <TokenEconomyWidget4/>
        </>
    );
}

const WapStyle = styled.div`
  ${WidthStyle.MaxWidth};

`;
