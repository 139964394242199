import React, {Component, useEffect} from "react";
import {Grid} from "@material-ui/core";
import styled from "styled-components";
import {ButtonWidget} from "../../../widget/pub/button";
import {WidthStyle} from "../../../style/width_style";
import H1Image from '../../../assets/imgs/logo_.gif'
// import PlayerContainer from 'griffith'
import {VodieWidget} from "./vodie";
import {LaunchButton} from "../../../widget/publi/launch_button";
// import {VodieWidget} from "./vodie";
// let bb =  require('../../../assets/mp4/logo.mp4').default;
const duration = 182


export const HomeWidget1: React.FC = () => {

    return (
        <WapStyle>
            <Grid container alignItems={"center"}>
                <LeftWidget item md={6} sm={12}>
                    <h2>Experience DeFi, </h2>
                    <h3>Risk Free.</h3>
                    <h4>
                        The risk marketplace that enables you to outsource the risks you need, and rewards
                        you for underwriting the ones you are comfortable with.
                    </h4>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <LaunchButton height={48} />
                            {/*<ButtonWidget href={'https://app.nsure.network'} text={'Launch App'}/>*/}
                        </Grid>
                        <Grid item md={6} sm={12}>
                            {/*<ButtonWidget href={'https://staking.nsure.network/#/'} text={'Underwriter Program'}/>*/}

                        </Grid>
                    </Grid>
                </LeftWidget>
                <Grid item md={6} sm={12}>

                    <VodieWidget/>

                    {/*<Image src={H1Image} alt=""/>*/}
                </Grid>
            </Grid>
        </WapStyle>
    )
}





const WapStyle = styled.div`
  ${WidthStyle.MaxWidth};
  max-width: 1100px;
  //height: 100vh;

  > div {
    flex-wrap: wrap-reverse !important;
    height: 100%;
  }

`
const LeftWidget = styled(Grid)`
  color: #270D4F;

  h2 {
    font-size: 38px;
    font-weight: 300;
  }

  h3 {
    font-size: 48px;
  }

  h4 {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    margin: 20px 0;
    //font-weight: bold;
  }
`
const Image = styled.img`
  display: block;
  width: 100%;
`
