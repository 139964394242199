import {kai as NetAxios} from './api'


export class HttpServer {
    static account: string | null;


    static activePolicyAndPremiums(): Promise<any> {
        return NetAxios.get('/statistics/activePolicyAndPremiums', {});
    }

    static activePremiumReturnbyProjects(): Promise<any> {
        return NetAxios.get('/statistics/activePremiumReturnbyProjects', {});
    }

    static activePolicybyProjects(): Promise<any> {
        return NetAxios.get('/statistics/activePolicybyProjects', {});
    }


    static policyValueAndAnnualizePremiums(): Promise<any> {
        return NetAxios.get('/statistics/policyValueAndAnnualizePremiums', {});
    }

    static nsureDepositAndPowerUsed(): Promise<any> {
        return NetAxios.get('/statistics/nsureDepositAndPowerUsed', {});
    }

    static estimatedThreeApr(): Promise<any> {
        return NetAxios.get('/statistics/estimatedThreeApr', {});
    }

    static capitalThree(): Promise<any> {
        return NetAxios.get('/statistics/capitalThree', {});
    }

    static coverTxes(data:{ name: string, sort: number,page: number, }): Promise<any> {
        return NetAxios.get('/statistics/coverTxes', {item: 10, page:data.page, name:data.name.toLowerCase(), sort:data.sort,});
    }


    static stakePower(): Promise<any> {
        return NetAxios.get('/statistics/stakePower', {});
    }


}
