import styled from "styled-components";
import {Grid} from "@material-ui/core";

export const TextCellStyle = styled(Grid)`
  color: #270D4F;
  span{
    display: inline-block;
    width: 30px;
    height: 2px;
    background: rgba(165, 74, 249, 1);
  }
  h2 {
    font-size: 38px;
  }

  h4{
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    margin: 20px 0;
    //font-weight: bold;
  }
`


export const ImageStyle = styled.img`
  display: block;
  width: 100%;
  @media (max-width: 768px) {
    width: 50%;
    margin: 0 auto;
  }
  
`
