import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {WapStyle} from "./w2";
import labelAc from "../../../assets/imgs/label_ac.png"
import upArrow from "../../../assets/imgs/up_arrow.png"
import dowArrow from "../../../assets/imgs/dow_arrow.png"


import {
    CircularProgress,
    Paper,
    Table,
    TableContainer,
} from "@material-ui/core";
import {HttpStatus, useWait} from "../../../utils/wait_fn";
import {HttpServer} from "../../../net/http_server";
import {getBalanceFormat} from "../../../utils/formatBalance";
import {DateUtils} from "../../../utils/date";
import {Pagination} from "@material-ui/lab";
import env from "@beam-australia/react-env";


export const SoldPolicies: React.FC = () => {
    return (
        <W6Style>
            <h2 className="title">Sold Policies</h2>
            <CustomizedTables/>
        </W6Style>
    );
}

const StyledTableCell = styled.div<{ align?: string }>`
  flex: 1;
  display: flex;
  justify-content: ${({align}) => align};
  align-items: center;
  padding: 0 20px;

  img {
    display: block;
    margin-left: 10px;
    width: 7px;
  }
  .name_img{
    width: 28px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 100%;
  }
`


function CustomizedTables() {
    const {request, data, status} = useWait<{ txes: CellInterface[], count: number }>(HttpServer.coverTxes);


    const [parameter, setParameter] = useState<parameterface>({
        name: "Start",
        sort: 0,
        page: 1,
    });

    const coverTxes = useCallback(async () => {
        await request(parameter);
    }, [data, parameter])

    useEffect(() => {
        coverTxes();
    }, [parameter]);

    return (
        <TableContainer component={Paper}>
            <Table aria-label="customized table">
                <TableHeadStyle>
                    <StyledTableCellWidget setParameter={setParameter} align={true} text={'Protocol'}
                                           parameter={parameter}/>
                    <StyledTableCellWidget setParameter={setParameter} text={'Amount'} parameter={parameter}/>
                    <StyledTableCellWidget setParameter={setParameter} text={'Cost'} parameter={parameter}/>
                    <StyledTableCellWidget setParameter={setParameter} text={'Start'} parameter={parameter}/>
                    <StyledTableCellWidget setParameter={setParameter} text={'End'} parameter={parameter}/>
                </TableHeadStyle>
                {
                    status == HttpStatus.success && data ? <TableBodyStyle>
                        {data.txes.map((cellInterface: CellInterface) => (
                            <div>
                                <StyledTableCell><img className={'name_img'} src={env("IMG_PATH")+cellInterface.logo.filename} alt=""/>{cellInterface.name}</StyledTableCell>
                                <StyledTableCell align="flex-end">{getBalanceFormat(cellInterface.amount)} ETH</StyledTableCell>
                                <StyledTableCell align="flex-end">{getBalanceFormat(cellInterface.cost)} ETH</StyledTableCell>
                                <StyledTableCell align="flex-end">{DateUtils.dataMinuteString(cellInterface.begin * 1000)}</StyledTableCell>
                                <StyledTableCell align="flex-end">{DateUtils.dataMinuteString(cellInterface.end * 1000)}</StyledTableCell>
                            </div>

                        ))}
                    </TableBodyStyle> : <></>
                }
            </Table>
            {
                status == HttpStatus.success && data && data.txes.length == 0 ? <div style={{
                    width: '100%',
                    height: '100px',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    N/A
                </div> : <></>
            }
            {
                status == HttpStatus.wait && <div style={{
                    width: '100%',
                    height: '200px',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <CircularProgress size={40}/>
                </div>
            }
            {
                data && data.count ? <div className="bottom_pages">
                    <Pagination count={Math.ceil((data && data.count || 1) / 10)}
                                shape="rounded"
                                onChange={(ev, index) => {
                                    setParameter({...parameter, page: index})
                                }}
                    />
                </div> : <></>
            }
        </TableContainer>
    );
}

const StyledTableCellWidget: React.FC<{
    align?: true,
    text: string,
    parameter: parameterface,
    setParameter: Function
}> = ({align, text, parameter, setParameter,}) => {
    return (
        <StyledTableCell
            align={align ? 'left' : 'flex-end'}
            onClick={() => {
                setParameter({
                    name: text,
                    sort: parameter.sort ? 0 : 1,
                    page: 1,
                })
            }}
        >
            <span className={parameter.name == text ? 'ac' : ''}>{text}</span> {parameter.name == text ? <>
            {parameter.sort ?  <img src={dowArrow} alt=""/>: <img src={upArrow} alt=""/> }
        </> : <img src={labelAc} alt=""/>}
        </StyledTableCell>
    );
}

interface parameterface {
    page: number,
    name: string,
    sort: number
}

interface CellInterface {
    amount: number;
    begin: number;
    name: String;
    cost: number;
    date: String;
    dateNum: number;
    end: number;
    hash: String;
    level: number;
    nonce: number;
    orderId: number;
    period: number;
    piece: number;
    product: number;
    status: number;
    surrenderDate: String;
    type: number;
    user: String;
    logo:any
}

const TableHeadStyle = styled.div`
  display: flex;
  padding: 10px 20px;
  background: #F6F6FA;

  .ac {
    color: #CE45C5;
  }
`
const TableBodyStyle = styled.div`

  > div {
    display: flex;
    color: #666666;
    padding: 20px 0;
    border-bottom: 1px solid #F5F5F5;
    >div{
      color: #666666;
      font-size: 14px;
      font-weight: 400;
    }
  }

`

export const W6Style = styled(WapStyle)`
  padding: 0;
  margin-bottom: 20px;
  min-height: 200px;

  .title {
    padding: 20px 0 30px 30px;
  }

  .bottom_pages {
    padding: 10px;
    display: flex;
    justify-content: flex-end;
  }

  .recharts-cartesian-axis-tick {
    text {
      tspan {
        font-size: 12px;
      }
    }
  }

  .MuiTableContainer-root {
    box-shadow: none;
  }
`
