import React, {useEffect} from "react";
import {
    Tooltip,
    Area,
    AreaChart,
    XAxis,
    ResponsiveContainer,
    CartesianGrid,
    TooltipProps,
    ContentRenderer, YAxis
} from 'recharts';
import styled from "styled-components";
import {useWait} from "../../../utils/wait_fn";
import {HttpServer} from "../../../net/http_server";
import BigNumber from "bignumber.js";
import {DateUtils} from "../../../utils/date";
import {getBalanceFormat, getBalanceNumber} from "../../../utils/formatBalance";

const c1 = "#3CDDE2";
const c2 = "#304EFB";
const c3 = "#FA6ABB";
type cellModal = {
    "date": string,
    "mcrCapital": string,
    "surplusCapital": number,
    "capitalCapital": number
};
export const CapitalMain: React.FC = () => {
    const {request, data} = useWait<{ all: cellModal[] }>(HttpServer.capitalThree);


    useEffect(() => {
        request('');

    }, [])

    return (
        <WapStyle>
            <h2 className={'title'}>
                Capital (in USD Million)
                <div className="label">
                    <LabelWidget color={c1} text={'Surplus Pool'}/>
                    <LabelWidget color={c2} text={'Capital Pool'}/>
                    <LabelWidget color={c3} text={'Minimum Capital Requirement'}/>
                </div>
            </h2>
            <div style={{height: "20px"}}/>
            <ChartWidget listData={data?.all || []}/>
        </WapStyle>
    );
}


export const ChartWidget: React.FC<{ listData: cellModal[], }> = ({listData}) => {


    return (
        <ResponsiveContainer
            width="100%" height="100%"
        >
            <AreaChart height={300} width={900} data={listData} margin={{top: 20, right: 0, bottom: 0, left: 0}}>
                <Tooltip
                    separator={': '}
                    labelFormatter={(label) => {
                        return '';
                    }}
                    formatter={(label: any) => getBalanceFormat(label, 0)}
                    // content={CustomTooltip}
                    label={'sss'}
                />
                <CartesianGrid strokeDasharray="1 1"/>
                <defs>
                    <linearGradient id="splitColors" x1="0" y1="0" x2="2" y2="100%">
                        <stop style={{stopColor: c1,}} offset={'0%'}/>
                        <stop style={{stopColor: c1 + '55',}} offset={'70%'}/>
                    </linearGradient>
                    <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="100%">
                        <stop style={{stopColor: c2,}} offset={'0%'}/>
                        <stop style={{stopColor: c2 + '55',}} offset={'70%'}/>
                    </linearGradient>
                    <linearGradient id="splitColor3" x1="0" y1="0" x2="0" y2="100%">
                        <stop style={{stopColor: c3,}} offset={'0%'}/>
                        <stop style={{stopColor: c3 + '55',}} offset={'90%'}/>
                    </linearGradient>

                </defs>
                <Area type="monotone"
                      dataKey='surplusCapital'
                      name="Surplus"
                      fill="url(#splitColors)"
                      width={5}
                      stroke={c1}
                />
                <Area type="monotone"
                      name="Capital"
                      dataKey='capitalCapital'
                      fill="url(#splitColor)"
                      stroke={c2}
                />
                <Area type="monotone"
                      dataKey='mcrCapital'
                      name="MCR"
                      fill="url(#splitColor3)"
                      stroke={c3}
                />
                <XAxis dataKey="date" tickFormatter={(label) => {
                    return DateUtils.dataNameString(label);
                }}/>
                <YAxis padding={{top: 40, bottom: 0}} tickFormatter={(label) => {
                    return  (getBalanceNumber(label, 0) / 1e6).toFixed(2) +" M";
                }}/>
            </AreaChart>

        </ResponsiveContainer>
    );
};


const CustomTooltip: ContentRenderer<TooltipProps> = ({
                                                          active,
                                                          payload,
                                                          label
                                                      }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`${label} : ${payload[0].value}`}</p>
                {/*<p className="intro">{getIntroOfPage(label)}</p>*/}
                <p className="desc">Anything you want can be displayed here.</p>
            </div>
        );
    }

    return null;
};

export const WapStyle = styled.div`
  box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  padding: 40px;
  min-height: 400px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;

  .title {
    font-size: 18px;
    font-weight: bold;
    color: #222222;
    line-height: 24px;
    display: flex;
    justify-content: space-between;

    .label {
      display: flex;
    }
  }

  .recharts-responsive-container {
    flex: 1;
  }
  .recharts-cartesian-axis-tick {
    text {
      tspan {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 900px) {
    padding: 20px;
  }
`

export const LabelWidget: React.FC<{ color: string, text: string }> = ({color, text}) => {
    return (
        <LabelStyle color={color}>
            <span/>
            {text}
        </LabelStyle>
    );
}
export const LabelStyle = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  margin-left: 15px;
  line-height: 1;

  span {
    display: block;
    width: 14px;
    height: 14px;
    background: ${({color}) => `linear-gradient(180deg, ${color} 0%, #FFFFFF 100%)`};
    border-radius: 100px;
    margin-right: 5px;
  }

  font-size: 12px;
  font-weight: 400;
  color: #666666;
`
