import React from 'react';
import './App.css';
import {HeaderWidget} from "./widget/header/header";
import {HashRouter as Router, Switch, Route, Redirect,} from "react-router-dom";
import {HomePage} from "./page/home";
import {BottomIndex} from "./widget/bottom/bottom_index";
import {CapitalPage} from "./page/capital";
import {TokenEconmyPage} from "./page/token_economy/token_economy";
import {UnNamePage} from "./page/stats";

function App() {
    return (
        <div className="App">

            <Router>
                <HeaderWidget/>
                <Switch>
                    <Route path="/home" component={HomePage}/>
                    <Route path="/capital/:id" component={CapitalPage}/>
                    <Route path="/capital" exact component={CapitalPage}/>
                    <Route path="/token_economy" component={TokenEconmyPage}/>
                    <Route path="/stats">
                        <UnNamePage/>
                    </Route>
                    <Redirect to="/home"/>
                </Switch>
                <BottomIndex />
            </Router>
        </div>
        // <Route>
        //
        //     <Switch>
        //         <Route path="/cover/">
        //             <div>
        //                 dasd
        //             </div>
        //         </Route>
        //     </Switch>
        //
        // </Route>

    );
}

export default App;
