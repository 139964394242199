import React from "react";
import {TitleStyle} from "../style";
import styled from "styled-components";
import {WidthStyle} from "../../../style/width_style";
import {makeStyles} from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import transitions from "@material-ui/core/styles/transitions";

export const TokenEconomyWidget4: React.FC = () => {
    return (
        <WapStyle>
            <div>
                <TitleStyle color={'#270D4F'}>
                    <span/>
                    <h2>Roadmap</h2>
                </TitleStyle>
                <div style={{height: '20px'}}/>
                <div className={'link_bg'}>
                    <CustomizedTimeline/>
                    <span className={'link'}/>
                </div>
            </div>

        </WapStyle>
    );
}

const WapStyle = styled.div`
  padding: 30px 0;
  box-sizing: border-box;
  background-color: #DFDCE4;

  > div {
    ${WidthStyle.MaxWidth};

    .link_bg {
      position: relative;

      .link {
        height: 100%;
        width: 2px;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        margin: 0 auto;
        background: linear-gradient(180deg, rgba(223, 220, 228, 1) 0%, rgba(84, 46, 225, 0.75) 56.99999999999999%);
        border-radius: 2px;
      }
    }
  }
`


const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px',
    },
    secondaryTail: {
        backgroundColor: 'red',
    },

}));

function CustomizedTimeline() {
    const classes = useStyles();

    return (
        <Timeline align="alternate">
            <TimelineCell left={true} title={'Q1 2020'} text={[
                'Whitepaper v0.1 release',
            ]}/>
            <TimelineCell left={false} title={'Q3 2020'}  text={[
                'MVP release',
                'Closed first stage Institutional Fundraise'
            ]}/>
            <TimelineCell left={true} title={'Q4 2020'} text={[
                `Liquidity Bootstrapping Pool on Balancer`,
                `Nsure Token Launch`,
                `Underwriter Program simulation Launch`,
                `Alpha pre-release on Kovan Network`,
            ]}/>
            <TimelineCell left={false} title={'Q1 2021'}  text={[
                'Full Alpha release & audit',
                'Mainnet v0.1 release (Beta)',
                'Bug bounty Program',
            ]}/>
            <TimelineCell left={true} title={'Q2 2021'} text={[
                'Nsure DAO Support: Community Governance',
            ]}/>
            <TimelineCell left={false} title={'Q3 2021'}  text={[
                'Multichain Deployment: Polygon, etc.',
            ]}/>
            <TimelineCell left={true} title={'Q4 2021'} text={[
                'Amplify product spectrum: Innovation zone, products to be listed via DAO ',
            ]}/>


            <TimelineCell left={false} title={'Q1 2022'}  text={[
                'Customised solution for third-party protocols',
            ]}/>
            <TimelineCell left={true} title={'Q2 2022'} text={[
                'Extend multichain environment: Finalisation of Polkadot deployment; Conflux/Oasis',
            ]}/>
            <TimelineCell left={false} title={'Q3 2022'}  text={[
                'B2B insurance: Protocol-to-Protocol insurance concept to be deployed on Beta',
            ]}/>
            <TimelineCell left={true} title={'Q4 2022'} text={[
                'Expansion into non-DeFi native insurance products. E.g. Natural catastrophes',
            ]}/>



        </Timeline>
    );
}

const TimelineCell: React.FC<{ left: boolean, title: string, text: string[] }> = ({left, title, text}) => {
    return (
        <ContentStyle>

            <TimelineSeparatorWidget left={left}/>
            <TimelineContent className={'content'}>
                <div>
                    <div className={`top ${left ? 'left' : 'right'}`}>
                        <h2>{title}</h2>
                    </div>
                    <div className="bottom">
                        {
                            text.map((ev) => <h3 key={ev}>{ev}</h3>)
                        }
                    </div>

                </div>
            </TimelineContent>
        </ContentStyle>
    );
};

const ContentStyle = styled(TimelineItem)`
        &:before{
          padding: 20px 30px !important;
        }
  .content {
    padding: 20px 30px !important;
    div {

      .top {

        &.left {
          border-left: 1px solid rgba(165, 74, 249, 1);

          h2 {
            border-image: linear-gradient(to right, rgba(165, 74, 249, 1), rgba(165, 74, 249, 0));
            border-top: 1px solid transparent;
            border-image-slice: 10;
          }
        }

        &.right {
          border-right: 1px solid rgba(165, 74, 249, 1);

          h2 {
            border-image: linear-gradient(to left, rgba(165, 74, 249, 1), rgba(165, 74, 249, 0));
            border-top: 1px solid transparent;
            border-image-slice: 10;
          }
        }

        h2 {
          padding: 0 31px 0 15px;
          display: inline-block;
          box-sizing: border-box;
          font-size: 20px;
          font-weight: 400;
          color: #270D4F;


        }
      }

      .bottom {
        padding: 0 15px;

        h3 {
          font-size: 14px;
          font-weight: 400;
          color: rgba(69, 36, 120, 1);
         
          
         
          span{
            flex: 1;
          }

        }
      }

    }

  }
`
const TimelineSeparatorWidget: React.FC<{ left: boolean }> = ({left}) => {
    return (
        <TimelineSeparatorStyle left={left ? 1:0}>
            <TimelineConnector>
                <span className={'border_img'}/>
                <span className={'line'}/>
            </TimelineConnector>

        </TimelineSeparatorStyle>
    );
}


const TimelineSeparatorStyle = styled(TimelineSeparator)<{ left: number }>`
  position: relative;

  > span {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 42px;
    left: -10px;
    right: -10px;
    margin: 0 auto;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(165, 74, 249, .4);
    border-radius: 100%;
  }

  .border_img {
    border-radius: 100%;
    height: 8px;
    width: 8px;
    background-color: rgba(165, 74, 249, 1);
  }

  .line {
    position: absolute;
    height: 1px;
    width: 31px;
    display: block;
    background-color: rgba(165, 74, 249, 1);
    right: ${({left}) => left ? '' : '50%'};
    left: ${({left}) => left ? '50%' : ''};
  }
`;
