import React, {useEffect} from "react";
import {Tooltip, Area, AreaChart, XAxis, ResponsiveContainer, YAxis, CartesianGrid} from 'recharts';
import {LabelWidget, WapStyle} from "./w2";
import {Grid} from "@material-ui/core";
import styled from "styled-components";
import Img from "../../../assets/imgs/icon@2x.png"
import {useWait} from "../../../utils/wait_fn";
import {HttpServer} from "../../../net/http_server";
import {DateUtils} from "../../../utils/date";
import {getBalanceFormat, getBalanceNumber} from "../../../utils/formatBalance";

const c4 = "#A30DFF";
const c2 = "#4546E4";
type cellModel = {
    averageLeverage: number
    date: string
    nsureDeposit: number
    powerUsed: number
};
export const W3Main: React.FC = () => {
    const {request, data} = useWait<{ averageLeverage: number, all: cellModel[] }>(HttpServer.nsureDepositAndPowerUsed);


    useEffect(() => {
        request('');
    }, [])
    return (
        <Grid container spacing={4}>
            <Grid item sm={8} xs={12}>
                <WapStyle>
                    <h2 className={'title'}>
                        Underwriting (in NSURE Million)
                        <div className="label">
                            <LabelWidget color={c4} text={'Staking Power Used'}/>
                            <LabelWidget color={c2} text={'Nsure Deposit'}/>
                        </div>
                    </h2>
                    <div style={{height: "20px"}}/>
                    <ChartWidget listData={data?.all || []}/>
                </WapStyle>
            </Grid>
            <Grid item sm={4} xs={12}>
                <Center>
                    <h2 className={'title'}>Average Leverage</h2>
                    <div className={'center'}>
                        <img src={Img} alt=""/>
                        <p>{getBalanceFormat((data?.averageLeverage ||  0) * 1e18, 2) }%</p>
                    </div>
                </Center>
            </Grid>
        </Grid>
    );
}

export const ChartWidget: React.FC<{ listData: any, }> = ({listData}) => {


    return (

        <ResponsiveContainer
            width="100%" height="100%"
        >
            <AreaChart height={300} width={900} data={listData} margin={{top: 20, right: 0, bottom: 0, left: 20}}>

                <Tooltip separator={': '}
                         labelFormatter={(label) => {
                             return '';
                         }}
                         formatter={(label: any) =>  getBalanceFormat(label, 0)}
                         label={'sss'}
                />
                <CartesianGrid strokeDasharray="1 1"/>
                <defs>
                    <linearGradient id="split2Colors" x1="0" y1="0" x2="0" y2="100%">
                        <stop style={{stopColor: c4,}} offset={'0%'}/>
                        <stop style={{stopColor: c4 + '55',}} offset={'70%'}/>
                    </linearGradient>
                    <linearGradient id="asplitColor" x1="0" y1="0" x2="0" y2="100%">
                        <stop style={{stopColor: c2,}} offset={'0%'}/>
                        <stop style={{stopColor: c2 + '55',}} offset={'70%'}/>
                    </linearGradient>

                </defs>
                <Area type="monotone"
                      dataKey='powerUsed'
                      name="Staking Power"
                      fill="url(#split2Colors)"
                      stroke={c4}
                />
                <Area type="monotone"
                      dataKey='nsureDeposit'
                      name="Nsure Deposit"
                      fill="url(#asplitColor)"
                      stroke={c2}/>
                <XAxis dataKey="date" tickFormatter={(label) => {
                    return DateUtils.dataNameString(label);

                }}/>
                <YAxis padding={{top: 40,bottom:0}} tickFormatter={(label) => {
                    return  (getBalanceNumber(label, 0) / 1e6).toFixed(2) +" M";
                }}/>

            </AreaChart>

        </ResponsiveContainer>
    );
};

const Center = styled(WapStyle)`
  display: flex;
  flex-flow: column;

  .center {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    img {
      display: block;
      width: 77px;
      height: 88px;
    }

    p {
      font-size: 36px;
      font-weight: bold;
      color: #222222;
    }
  }
`



