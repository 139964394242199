import React from "react";
import {Grid} from "@material-ui/core";
import {ButtonWidget} from "../../../widget/pub/button";
import H2Image from "../../../assets/imgs/h2.png";
import styled from "styled-components";
import {WidthStyle} from "../../../style/width_style";
import {ImageStyle, TextCellStyle} from "../style";
import h3Bg from '../../../assets/imgs/h3_bg.png'

export const HomeWidget3: React.FC = () => {
    return (
        <BgStyle>
            <WapStyle container alignItems={"center"} justify={"space-between"}>

                <TextCellStyle item md={5} sm={12}>
                    <span/>
                    <h2>Open Market to Trade Risk</h2>
                    <h4>
                        Nsure.Network is a permissionless platform for whoever wants to purchase coverage. Capital
                        providers can utilize NSURE to stake on specific insurance risks to obtain daily insurance
                        premiums. Leverage staking is available for non-correlated insurance products.
                    </h4>
                    <ButtonWidget href={'https://app.nsure.network/#/start_list/portfolio/'} text={'Start Underwriting'}/>
                </TextCellStyle>
                <Grid item md={5} sm={12}>
                    <ImageStyle src={H2Image} alt=""/>
                </Grid>

            </WapStyle>
        </BgStyle>

    );
}

const BgStyle = styled.div`
  background-image: url("${h3Bg}");
  background-repeat: no-repeat;
  background-position: top left;

  @media (max-width: 980px) {
    background-position: center;


  }

`

const WapStyle = styled(Grid)`
  ${WidthStyle.MaxWidth};
  max-width: 1100px;
  width: 100%;
  flex-wrap: wrap-reverse !important;
  margin-top: 30px;
  margin-bottom: 100px;
`

