import React, {useEffect} from "react";
import {Area, AreaChart, Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {WapStyle} from "./w2";
import {useWait} from "../../../utils/wait_fn";
import {HttpServer} from "../../../net/http_server";
import styled from "styled-components";
import {getBalanceFormat, getBalanceNumber} from "../../../utils/formatBalance";


type cellModel = {
    activePolicyValue: number
    cost: number
    name: string
    stakingPool: number
}
export const W6Widget: React.FC = () => {

    const {
        request,
        data
    } = useWait<{ list: cellModel[] }>(HttpServer.activePolicybyProjects);


    useEffect(() => {
        request('');
    }, [])

    return (
        <W6Style>
            <h2 className="title">Policy Value (in USD Million)</h2>
            <AverAgeWidget listData={data?.list || []}/>
        </W6Style>
    );
}

export const AverAgeWidget: React.FC<{ listData: any }> = ({listData}) => {

    const c1 = "#2D97EE";
    const c2 = "#fff";


    return (

        <ResponsiveContainer
            width="100%" height="100%"
        >
            <BarChart height={400} width={900} data={listData} margin={{top: 10, right: 0, bottom: 90, left: 0}}>

                <Tooltip
                    separator={''}
                    labelFormatter={(label) => {
                        return label;
                    }}
                    labelStyle={{
                        "color":"#8884d8"
                    }}
                    formatter={(label: any) => getBalanceFormat(label * 1e18, 0)}

                    label={'sss'}
                />
                <CartesianGrid strokeDasharray="1 1"/>
                <defs>
                    <linearGradient id="split" x1="0" y1="0" x2="0" y2="100%">
                        <stop style={{stopColor: c1,}} offset={'0%'}/>
                        <stop style={{stopColor: c2,}} offset={'70%'}/>
                    </linearGradient>
                </defs>
                <XAxis dataKey="name"
                       interval={0}
                       dy={40}
                       angle={70}
                       dx={10}
                       tickFormatter={(label) => {
                           return label
                       }}
                />
                <YAxis padding={{top: 60,bottom:0}}
                       tickFormatter={(label) => {
                           return (getBalanceNumber(label * 1e18, 0) / 1e6).toFixed(2) +" M"
                       }}
                />
                <Bar dataKey="activePolicyValue"  name=" " fill="#8884d8"/>
            </BarChart>

        </ResponsiveContainer>

    );
};


export const W6Style = styled(WapStyle)`
  height: 500px;

  .recharts-cartesian-axis-tick {
    text {
      tspan {
        font-size: 12px;
      }
    }
  }
`
