import React from "react";
import {Grid} from "@material-ui/core";
import {ButtonWidget} from "../../../widget/pub/button";
import H2Image from "../../../assets/imgs/h4.png";
import styled from "styled-components";
import {WidthStyle} from "../../../style/width_style";
import {ImageStyle, TextCellStyle} from "../style";
import h4Bg from '../../../assets/imgs/h4_bg.png'

export const HomeWidget4: React.FC = () => {
    return (
        <BgStyle>
            <WapStyle>
                <Grid container alignItems={"center"} justify={"space-between"}>
                    <Grid item md={5} sm={12}>
                        <ImageStyle src={H2Image} alt=""/>
                    </Grid>
                    <TextCellStyle item md={5} sm={12}>
                        <span />
                        <h2>
                            Dynamic Pricing and Capital
                            Management
                        </h2>
                        <h4>
                            Pricing is determined by real-time supply of capital and demand of insurance coverage for the products. The capital model ensures that valid claims will always be paid and that systematic risk is under control.
                        </h4>
                        <ButtonWidget href={"https://app.nsure.network/#/cover/my"} text={'Get Quote'}/>
                    </TextCellStyle>

                </Grid>
            </WapStyle>
        </BgStyle>
    );
}

const BgStyle = styled.div`
  background-image: url("${h4Bg}");
  background-repeat: no-repeat;
  background-position: top left;

  @media (max-width: 980px) {
    background-position: center;


  }

`




const WapStyle = styled.div`
${WidthStyle.MaxWidth};

`
