import React from "react";
import ReactPlayer from 'react-player'
import styled from "styled-components";
const aa = require("../../../assets/mp4/home_vodie.mp4").default;
export const VodieWidget: React.FC = () => {
    return (
        <VodieStyle>
            <ReactPlayer width={'100%'} muted={true} playing={true} url={aa} loop={true} controls={false}/>
            {/*<div className={'bg'}/>*/}
        </VodieStyle>
    );
}
const VodieStyle = styled.div`
  position: relative;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
`
